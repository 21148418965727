import React from 'react';
import { Helmet } from "react-helmet";
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)

function App() {
  return (
    <section className="section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pét-Nat Slaps by Pashmina Papi</title>
        <meta name="description" content="A monthly mix series featuring the best jazz, hip hop, soul, house and afrobeats." />
        <link rel="canonical" href="https://petnatslaps.club" />
      </Helmet>

      <div className="container">
        <div className="hero">
          <div className="hero-body">
            <h1 className="title">
              Pét-Nat Slaps
          </h1>
            <p className="subtitle">
              A mix series by Pashmina Papi
        </p>

            <p className="description">A bi-monthly mixtape series featuring some of the best jazz, hip hop, soul, house and afrobeats music. Produced by Aaron Williams aka Pashmina Papi, a lifelong music nerd, former radio dj, and struggling rap music icon.</p>

            <h2 className="has-text-weight-semibold">Listen to Vols. 1-4 on</h2>

            <ul>
              <li><a href="https://soundcloud.com/pashminapapi/pet-nat-slaps-vol-4?in=pashminapapi/sets/pet-nat-slaps" title="Listen to Pét-Nat Slaps on Soundcloud"><FontAwesomeIcon icon={['fab', 'soundcloud']} /> SoundCloud</a> (continuous mix w/ exclusives <span aria-labelledby="fire" aria-label="1" role="img">🔥</span>)</li>
              <li><a href="https://music.apple.com/us/playlist/p%C3%A9t-nat-slaps-vol-1-4/pl.u-Zmbl3Bmc0zyabx" title="Listen to Pét-Nat Slaps on Apple Music"><FontAwesomeIcon icon={['fab', 'apple']} /> Music</a> (playlist)</li>
              <li><a href="https://open.spotify.com/playlist/5vKwOMHT7ONfYFKrzuSsFg?si=IIiF8R0wTMSPnFl8YtsxFQ" title="Listen to Pét-Nat Slaps on Spotify"><FontAwesomeIcon icon={['fab', 'spotify']} /> Spotify</a> (playlist)</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
